import Tooltip from '@mui/material/Tooltip';
import React, { useState, useEffect } from "react";
import {
    DotMenuIcon,
    TrashIcon,
    EyeIcon2,
    EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { routes } from "../../Api/routes";
import { postRequest } from "../../Api";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import { Dropdown, Menu, Button, Typography, Carousel } from "antd";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import Modal from "../../components/Modal";
// import AddPostModel from "../../components/AddPostModal";
import RejectedReasonModel from "../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { Image } from 'antd';
import { useNavigate } from "react-router-dom";
import AddCategoryModel from "../../components/AddCategoryModel";
import { collection, firestore, getDocs, addDoc, deleteDoc, doc } from "../../firebase";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import EditCategoryModel from "../../components/EditCategoryModel";
import AddProductModel from "../../components/AddProductModel";
import EditProductModel from "../../components/EditProductModel";
import { useDeleteProductMutation } from '../../store/services/storesService';

const ProductsTable = ({
    loadingState = () => { },
    data, refreshData = () => { },
    categories = [],

}) => {
    const words = useSelector((state) => state.authReducer.words);
    const lang = useSelector((state) => state.authReducer.lang);
    const isAr = lang == 'ar';

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editItem, setEditItem] = useState();
    const [deletedId, setDeletedId] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [deleteProduct, response] = useDeleteProductMutation();


    const columns = [

        {
            title: words["Name"],
            dataIndex: "name",
            key: "name",
        },
        {
            title: words["Images"],
            dataIndex: "image",
            key: "image",
            render: (_, record) => (
                <Carousel
                    indicators={false}

                    style={{ height: 80, borderRadius: 10, marginTop: 5, width: 80 }} >
                    {record?.images.map((image, index) => (
                        <Image key={index} src={IMAGE_URL + image?.image_path} alt="slider-item" style={{ width: "100%", height: 80, objectFit: 'cover' }} />

                    ))}
                </Carousel>
            )
        },
        {
            title: words["Description"],
            dataIndex: "description",
            key: "description",
            render: (_, record) => (
                <Tooltip
                    title={record.description}
                    arrow
                >
                    <div

                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            maxWidth: 100,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap', // To ensure text doesn't wrap
                            textOverflow: 'ellipsis' // To show ellipsis when text overflows
                        }}
                    >
                        {record.description}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: words["Price"],
            dataIndex: "price",
            key: "price",
        },
        {
            title: words["Sale price"],
            dataIndex: "sale_price",
            key: "sale_price",
        },
        {
            title: words["Category"],
            dataIndex: "category",
            key: "category",
        },


        {
            title: words["Created at"],
            // dataIndex: "created_at",
            key: "created_at",
            render: (_, record) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {getDateString(record.created_at)}
                </div>
            ),
        },
        {
            title: words["Action"],
            key: "action",
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu
                            onClick={({ key }) => {

                                if (key == 'delete') {
                                    setDeletedId(record.id);
                                    setOpenDeleteModal(true);
                                }
                                if (key == 'edit') {
                                    setEditItem(record);
                                    setEditModal(true);
                                }
                            }}
                        >
                            <>
                                <Menu.Item key="edit">{words["Edit"]}</Menu.Item>
                                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
                            </>
                        </Menu>
                    }
                    trigger={["click"]}
                >
                    <div className="flex items-center  cursor-pointer text-[#0025F2]">
                        <DotMenuIcon />
                    </div>

                </Dropdown>
            ),
        },
    ];






    const doDeleteProduct = async () => {
        try {
            loadingState(true)
            deleteProduct(deletedId);
            // const docRef = doc(firestore, 'products', deletedId);
            // await deleteDoc(docRef);
            // toast.success('Record deleted successfully');
            // refreshData();


        } catch (e) {
            loadingState(false)
            console.log(e)
            toast.error('Error deleting document: ', e);
        }

    };


    React.useEffect(() => {

        console.log('the response',response);
        if (response.error?.data?.status == 0) {
            loadingState(false)
            toast.error(response.error?.data?.error);
        }
        if (response.data?.status == 1) {
            loadingState(false);
            toast.success('Product deleted!');
        }
        if (response.isError) {
            loadingState(false)
        }
    }, [response.isLoading]);






    return (
        <>

            <div className="flex flex-col w-full gap-4">
                <div className="flex items-center justify-between gap-3">

                    <div className="flex flex-1 justify-end">
                        <div
                            style={{ zIndex: 1 }}
                            onClick={() => setOpenModal(true)}
                            className="!flex items-center button bg-themeColor rounded-full gap-2 !py-1"
                        >
                            <div className="flex items-center justify-center text-white font-normal text-2xl">
                                +
                            </div>
                            <label className="text-white cursor-pointer">{words["Add"]}</label>
                        </div>
                    </div>


                </div>

                <div style={{ marginTop: -50 }}>
                    <DataTable
                        searchContainerStyle={{ width: "40%" }}
                        data={data}
                        columns={columns}
                    />
                </div>

                <Modal
                    open={openModal} setOpen={setOpenModal}>
                    <AddProductModel
                        categories={categories}
                        onSuccessCreate={() => {
                            setOpenModal(false);
                            refreshData();
                        }}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                    />
                </Modal>
                <Modal
                    open={editModal} setOpen={setEditModal}>
                    <EditProductModel
                        categories={categories?.data}
                        onSuccessCreate={() => {
                            setEditModal(false);
                            refreshData();
                        }}
                        loadingState={loadingState}
                        data={editItem}
                        openModal={openModal}
                        setOpenModal={setEditModal}
                    />
                </Modal>

                <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
                    <DeleteConfirmationModel
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                        onSubmit={() => {
                            doDeleteProduct();
                        }}
                    />
                </Modal>



            </div>
        </>
    );
};

export default ProductsTable;
