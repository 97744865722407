import { Dropdown, Menu, Button, Typography } from "antd";
import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import NotificationModel from "../../components/NotificationModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { useDeleteNotificationMutation, useGetNotificationsQuery } from "../../store/services/notificationService";
import DataTable from "../../components/DataTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { useSelector } from "react-redux";
import AppLoader from "../../components/Loader/AppLoader";
import { toast } from "react-toastify";
import { useGetUsersQuery } from "../../store/services/userService";

const Notification = () => {
  const words = useSelector((state) => state.authReducer.words);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { data: users } = useGetUsersQuery();
  const [openModal, setOpenModal] = useState(false);

  const [deletedId, setDeletedId] = useState("");

  const { data: notificationsList } = useGetNotificationsQuery();






  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
    },
    {
      title: words["Description"],
      dataIndex: "description",
      key: "description",
    },

    
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == 'delete') {
                  setDeletedId(record.group_id)
                  setOpenDeleteModal(true);
                  return;
                }
              }}
            >
              <>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },


  ];





  return (
    <>

      <Layout pageTitle={words["Notification"]}>
        <div className="notification-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                    style={{ zIndex: 1 }}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">{words["Add"]}</label>
                  </button>
                </div>
              </div>
              {/* <div className="responsive-table overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#F5F7FB]">
                  <tr>
                    <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                      No
                    </th>

                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Title
                    </th>
                    <th className="px-2 py-2 text-left break-words text-sm text-black font-medium">
                      Description
                    </th>
                    <th className="px-2 py-2 text-left text-sm text-black font-medium">
                      Date
                    </th>
                    <th className="px-2 py-2 text-center text-sm text-black font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((item, index) => (
                    <TableRow item={item} index={index} />
                  ))}
                </tbody>
              </table>
            </div> */}

              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={notificationsList?.data ?? []}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NotificationModel
              users={users?.data ?? []}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          {/* <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              onSubmit={() => deleteNotifications(deletedId)}
            />
          </Modal> */}
        </div>
      </Layout>
    </>
  );
};

export default Notification;
