import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const storesServices = createApi({
  reducerPath: "stores",
  tagTypes: "store",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      // const token = reducers?.authReducer?.token;
      const token = localStorage.getItem('admin-token')
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {

      getProducts: builder.query({
        query: () => {
          return {
            url: "/api/admin/products",
            method: "GET",
          };
        },
        providesTags: ["store"],
      }),

      addProduct: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/products",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["store"],
      }),

      editProduct: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/edit_product",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["store"],
      }),

      deleteProduct: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/products/" + id,
            method: "DELETE",
            // body: data,
          };
        },
        invalidatesTags: ["store"],
      }),


      getCategories: builder.query({
        query: () => {
          return {
            url: "/api/admin/categories",
            method: "GET",
          };
        },
        providesTags: ["store"],
      }),

      addCategory: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/categories",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["store"],
      }),

      editCategory: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/edit_categories",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["store"],
      }),

      deleteCategory: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/categories/" + id,
            method: "DELETE",
            // body: data,
          };
        },
        invalidatesTags: ["store"],
      }),


      getOrders: builder.query({
        query: () => {
          return {
            url: "/api/admin/orders",
            method: "GET",
          };
        },
        providesTags: ["store"],
      }),

      getOrderDetails: builder.query({
        query: (id) => {
          return {
            url: "/api/admin/orders/" + id,
            method: "GET",
          };
        },
        providesTags: ["store"],
      }),





    };
  },
});
export const { useGetProductsQuery, useAddProductMutation, useEditProductMutation, useDeleteProductMutation,
  useGetCategoriesQuery, useAddCategoryMutation, useEditCategoryMutation, useDeleteCategoryMutation,
  useGetOrdersQuery, useGetOrderDetailsQuery
} = storesServices;
export default storesServices;
