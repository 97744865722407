import moment from 'moment-timezone';

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
    DotMenuIcon,
    TrashIcon,
    EyeIcon2,
    EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddUserModel from "../../components/AddUserModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { Dropdown, Menu, Button, Image, Tooltip } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, updateDoc, doc } from "../../firebase";

const UsersPosts = ({
    posts = []
}) => {
    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersTemp, setUserTemp] = useState([]);
    const [deletedId, setDeletedId] = useState("");

    const columns = [
        {
            title: words["Id"],
            dataIndex: "id",
            key: "id",
        },
        {
            title: words["Title"],
            dataIndex: "title",
            key: "title",
        },
        {
            title: words["Description"],
            dataIndex: "description",
            key: "description",
            render: (_, record) => (
                <Tooltip
                    title={record.description}
                    arrow
                >
                    <div

                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            maxWidth: 100,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {record.description}
                    </div>
                </Tooltip>
            ),

        },

        {
            title: words["Likes"],
            dataIndex: "likes",
            key: "likes",
        },


        {
            title: words["Status"],
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                    <div
                        // style={{ color: 'white' }}
                        className={`py-1 px-2 rounded-lg text-center font-semibold border ${record.status === "Complete"
                            ? "bg-[#0DB214]/40 text-[#0DB214] border-[#0DB214]"
                            : record.status === "published"
                                ? "bg-[#4F52FF]/40 text-[#4F52FF] border-[#4F52FF]"
                                : "bg-[#FFC005]/40 text-[#FFC005] border-[#FFC005]"
                            }`}
                    >
                        {capitalizeFirstLetter(record.status)}
                    </div>
                </td>
            )
        },




       
    ];




    return (


        <div>
            <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={posts}
                columns={columns}
            />
        </div >

    );
};

export default UsersPosts;
