import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, Button, Link } from '@mui/material';
import { Folder } from '@mui/icons-material';

import { Tab, Tabs, Avatar, } from '@mui/material';
import CakeIcon from '@mui/icons-material/Cake';
import FemaleIcon from '@mui/icons-material/Female';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PeopleIcon from '@mui/icons-material/People';
import PostAddIcon from '@mui/icons-material/PostAdd';


import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import TextEditor from "../../components/TextEditor";
import {
    useGetSettingQuery,
    useCreateSettingMutation,
} from "../../store/services/settingService";
import Loader from "../../components/Loader";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import AppLoader from '../../components/Loader/AppLoader';
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, updateDoc, doc, setDoc } from "../../firebase";
import { Icons } from "../../assets/Icons/Svgs";
import { useParams } from "react-router-dom";
import { getDoc } from "firebase/firestore";
import { Image } from "antd";
import Stages from "./Stages";
import UsersPosts from "./UserPosts";
import { useGetUsersDetailsQuery } from "../../store/services/userService";
import { calculateAge } from "../../utils/functions";


const UserDetails = () => {

    const words = useSelector((state) => state.authReducer.words);
    const { id } = useParams();


    const { data } = useGetUsersDetailsQuery(id);
    const [loading, setLoading] = useState(false);

    const [userDetails, setUserDetails] = useState({});

    const tabs = [
        {
            label: words["User Info"],
            value: "info",
        },
        {
            label: words["Career planning Tools"],
            value: "career",
        },
        {
            label: words["Stages"],
            value: "stages",
        },
        {
            label: words["Posts"],
            value: "posts",
        },
    ];


    const [activeTab, setActiveTab] = useState(tabs[0].value);

    const labelStyle = {
        fontFamily: 'PRe',
        color: '#979797',
        fontSize: 15
    }

    const valueStyle = {
        color: '#000',
        fontFamily: 'PMe',
        fontSize: 15
    }

    useEffect(() => {
        if (data) {
            setUserDetails(data?.data ?? {})
        }
    }, [data])

    const Seperator = () => {
        return (
            <div style={{ width: "100%", marginTop: 10, marginBottom: 10, height: 1, backgroundColor: '#979797' }} />
        )
    }




    const UserInfoTab = useCallback(() => {
        return (
            <div className="p-4 rounded-lg shadow-md bg-white">
                <div className="flex items-center mb-4">
                    <Image
                        src={IMAGE_URL + userDetails?.image}
                        alt="User Avatar"
                        style={{ width: 90, height: 90, borderRadius: 90 / 2 }}
                    />
                    <div className="ml-4">
                        <h2
                            style={{ fontFamily: 'PMe' }}
                            className="text-lg font-bold text-gray-800">{userDetails?.fullname}</h2>
                        <p
                            style={{ fontFamily: 'PRe' }}
                            className="text-themeColor">{userDetails?.dream_job}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.ageIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Age</p>
                            </div>
                            <p
                                style={valueStyle}
                                className="text-gray-600">{calculateAge(userDetails?.dob)}</p>
                        </div>
                        <Seperator />
                    </div>
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.genderIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Gender</p>
                            </div>
                            <p
                                style={valueStyle}
                                className="text-gray-600">{userDetails?.gender}</p>
                        </div>
                        <Seperator />
                    </div>
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.hobbiesIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Habbits</p>
                            </div>
                            <p style={valueStyle} className="text-gray-600">
                                {userDetails?.habbits?.map((habit, index) => (
                                    <span key={index}>
                                        {habit}
                                        {index < userDetails.habbits?.length - 1 && <br />}
                                    </span>
                                ))}
                            </p>
                        </div>
                        <Seperator />
                    </div>
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.postsIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Posts</p>
                            </div>
                            <p
                                style={valueStyle}
                                className="text-gray-600">{userDetails?.posts?.length}</p>
                        </div>
                        <Seperator />
                    </div>
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.followersIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Followers</p>
                            </div>
                            <p
                                style={valueStyle}
                                className="text-gray-600">{userDetails?.followers_count}</p>
                        </div>
                        <Seperator />
                    </div>
                    <div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="gap-2"
                                style={{ display: 'flex', alignItems: 'center', width: 200 }}>
                                <Icons.pointsIcon />
                                <p
                                    style={labelStyle}
                                    className="text-gray-700 font-medium">Points</p>
                            </div>
                            {/* <p
                                style={valueStyle}
                                className="text-gray-600">0</p> */}
                        </div>
                    </div>
                </div>
            </div>


        )
    }, [userDetails]);

    const primaryColor = '#8434FC';

    const SectionCard = ({ title, children }) => (
        <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
            <Typography variant="subtitle1" fontWeight="bold" mb={1}>
                {title}
            </Typography>
            {children}
        </Paper>
    );


    // const getUserDetails = async () => {
    //     setLoading(true);
    //     const docRef = doc(firestore, 'users', id);
    //     const docSnap = await getDoc(docRef);
    //     setLoading(false);
    //     if (docSnap.exists()) {
    //         setUserDetails(docSnap.data());
    //     } else {
    //         toast.error('No user exists')
    //         console.log("No such document!");
    //         return null;
    //     }

    // }





    return (
        <>

            {loading && <AppLoader />}
            <Layout
                pageTitle={"Users"}>
                <div className="setting-page min-h-screen flex">
                    <div className="wrap wrapWidth flex flex-col">

                        <div className="flex flex-col gap-5 mt-6">
                            <div
                                style={{ borderWidth: 1, }}
                                className="flex w-full border-b-2 border-b-themeColor border-transparent ">
                                {tabs.map((item, index) => (
                                    <button
                                        style={{ fontFamily: 'PRe' }}
                                        key={index}
                                        className={`btn font-normal rounded ${activeTab === item.value
                                            ? " bg-themeColor text-white"
                                            : "bg-transparent text-black"
                                            }`}
                                        onClick={(e) => setActiveTab(item.value)}
                                    >
                                        {item.label}
                                    </button>
                                ))}
                            </div>
                            {
                                activeTab == 'info' && <UserInfoTab />
                            }
                            {
                                activeTab == 'career' && <Box sx={{ padding: 4 }}>
                                    <Grid container spacing={2}>
                                        {/* Personality Assessment */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Personality Assessment">
                                                <Box mb={2}>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Strengths:
                                                    </Typography>
                                                    <Typography variant="body2">Leadership - 55%</Typography>
                                                    <Typography variant="body2">Teamwork - 55%</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Working Style:
                                                    </Typography>
                                                    <Typography variant="body2">Leadership - 55%</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                            </SectionCard>
                                        </Grid>

                                        {/* Goal Setting */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Goal Setting">
                                                {['Short-Term', 'Short-Term', 'Long-Term'].map((term, index) => (
                                                    <Box mb={2} key={index}>
                                                        <Typography variant="body2" color={primaryColor}>
                                                            {term}: Select
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            Set Time: 24/01/2024 - 24/01/2025
                                                        </Typography>
                                                        <Typography variant="body2">Other</Typography>
                                                    </Box>
                                                ))}
                                            </SectionCard>
                                        </Grid>

                                        {/* Skill Development */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Skill Development">
                                                {['Teamwork', 'Networking'].map((skill, index) => (
                                                    <Box display="flex" alignItems="center" mb={2} key={index}>
                                                        <Typography variant="body2" sx={{ flex: 1 }}>
                                                            <Link href="#" underline="none" color={primaryColor}>
                                                                Soft Skills
                                                            </Link>
                                                            : {skill}
                                                        </Typography>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<Folder />}
                                                            sx={{ borderColor: primaryColor, color: primaryColor }}
                                                        >
                                                            Name File
                                                        </Button>
                                                    </Box>
                                                ))}
                                                <Typography variant="body2">Other</Typography>
                                            </SectionCard>
                                        </Grid>

                                        {/* Exploration */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Exploration">
                                                <Box mb={2}>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Career Workshops:
                                                    </Typography>
                                                    <Typography variant="body2">Teamwork</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Internships or Volunteering:
                                                    </Typography>
                                                    <Typography variant="body2">Teamwork</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                            </SectionCard>
                                        </Grid>

                                        {/* Research and Information Gathering */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Research and Information Gathering">
                                                <Box>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Educational Pathways:
                                                    </Typography>
                                                    <Typography variant="body2">University degree</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                            </SectionCard>
                                        </Grid>

                                        {/* Academic Planning */}
                                        <Grid item xs={12} md={4}>
                                            <SectionCard title="Academic Planning">
                                                <Box>
                                                    <Typography variant="body2" color={primaryColor}>
                                                        Subject Selection:
                                                    </Typography>
                                                    <Typography variant="body2">Mathematics</Typography>
                                                    <Typography variant="body2">Other</Typography>
                                                </Box>
                                            </SectionCard>
                                        </Grid>

                                        {/* Networking Opportunities */}
                                        <Grid item xs={12} md={6}>
                                            <SectionCard title="Networking Opportunities">
                                                {['User you interested in:', 'Need Consultation, Mentoring, Coaching:', 'Provide Consultation, Mentoring, Coaching:'].map((label, index) => (
                                                    <Box mb={2} key={index}>
                                                        <Typography variant="body2" color={primaryColor}>
                                                            {label}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            @ahamd232 , @ahamd232 , @ahamd232 , @ahamd232 , @ahamd232 , @ahamd232 , @ahamd232 , @ahamd232
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </SectionCard>
                                        </Grid>

                                        {/* Project/Talent Showcase */}
                                        <Grid item xs={12} md={6}>
                                            <SectionCard title="Project/Talent Showcase">
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<Folder />}
                                                    sx={{ borderColor: primaryColor, color: primaryColor }}
                                                >
                                                    Name File
                                                </Button>
                                            </SectionCard>
                                        </Grid>

                                        {/* Badges */}
                                        <Grid item xs={12} md={3}>
                                            <SectionCard title="Badges">
                                                {['Infancy', 'Developing', 'Established', 'Advanced', 'Leading'].map((badge, index) => (
                                                    <Typography
                                                        variant="body2" mb={1} key={index}>
                                                        {index + 1}: {badge}
                                                    </Typography>
                                                ))}
                                            </SectionCard>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }

                            {
                                activeTab == 'stages' && <Stages />
                            }
                            {
                                activeTab == 'posts' && <UsersPosts posts={userDetails?.posts} />
                            }

                        </div>

                    </div>
                </div>
            </Layout>
        </>


    );
};

export default UserDetails;



