// export const IMAGE_URL = "http://127.0.0.1:8000/";
// export const BASE_URL = "http://127.0.0.1:8000";
// export const API_URL = "http://127.0.0.1:8000/api/";

// 
export const API_URL = "https://magic-pages.etmaan.org/backend/public/api/";
export const BASE_URL = "https://magic-pages.etmaan.org/backend/public";
export const IMAGE_URL = "https://magic-pages.etmaan.org/backend/";


// export const API_URL = 'http://khanjar.akwantechno.com/backend/public/api/';
// export const BASE_URL = 'http://khanjar.akwantechno.com/backend/public';
// export const IMAGE_URL = 'http://khanjar.akwantechno.com/backend/';