import moment from 'moment-timezone';

import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddUserModel from "../../components/AddUserModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { Link, useNavigate } from "react-router-dom";
import { getDateString } from "../../utils/functions";
import { Dropdown, Menu, Button, Image } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { collection, firestore, getDocs, addDoc, updateDoc, doc } from "../../firebase";
import { useGetUsersQuery } from '../../store/services/userService';
import { IMAGE_URL } from '../../Api/constants';
import AppLoader from '../../components/Loader/AppLoader';

const Users = () => {
  const words = useSelector((state) => state.authReducer.words);
  const { data: users, isLoading } = useGetUsersQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [users, setUsers] = useState([]);
  const [usersTemp, setUserTemp] = useState([]);
  const [deletedId, setDeletedId] = useState("");

  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Image"],
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <Image
          src={IMAGE_URL + record.image}
          style={{ width: 50, height: 50, borderRadius: 50 / 2 }}
        />
      )
    },
    {
      title: words["Name"],
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: words["Email"],
      dataIndex: "email",
      key: "email",
    },
    {
      title: words["Phone Number"],
      dataIndex: "number",
      key: "number",
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => {
        return (
          <div>
            <div
              className="px-2 py-2 text-left text-sm text-black font-medium"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {getDateString(record.created_at)}
            </div>
          </div>
        )
      },
    },


    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/user-details/" + record.id);
                } else if (key == "delete") {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>

        </Dropdown>
      ),
    },
  ];

  // const getUsers = async () => {

  //   try {
  //     const collectionRef = collection(firestore, 'users');
  //     const snapshot = await getDocs(collectionRef);

  //     const docs = snapshot.docs.map(doc => {
  //       return {
  //         ...doc.data(),
  //         id: doc.id
  //       }

  //     });

  //     setUsers(docs);


  //   }
  //   catch (err) {

  //     toast.error(err);
  //   }

  // };


  const doDeleteUser = async () => {
    const onSuccess = (res) => {
      setDeletedId("");
      setLoading(false);
      // getUsers();
    };

    const onError = (err) => {
      typeof err == 'string' && toast.error(err);
      setDeletedId("");
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest(
      { id: deletedId },
      routes.deleteUser,
      true,
      onSuccess,
      onError
    );
  };



  return (
    <Layout pageTitle={words["Users"]}>
      {isLoading &&
        <AppLoader />
      }

      <div className="store-page min-h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="flex flex-col gap-5 mt-6">


            <div>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={users?.data ?? []}
                columns={columns}
              />
            </div>
          </div>
        </div>
        <Modal open={openModal} setOpen={setOpenModal}>
          <AddUserModel
            // onSuccessCreate={() => getUsers()}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>
        <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
          <DeleteConfirmationModel
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            onSubmit={() => {
              doDeleteUser();
            }}
          />
        </Modal>
      </div>

    </Layout>
  );
};

export default Users;
