import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography } from "antd";

import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import {
  DotMenuIcon,
  NotesIcon,
  IncomeIcon,
  UsersIcon,
  ChartIcon,
  TrashIcon,
  EyeIcon2,
} from "../../assets/Icons";
import NewsDetailsModel from "../../components/NewsDetailsModel";
import { getRequest, postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import {
  useApproveAdMutation,
  useGetAdsQuery,
  useRejectAdMutation,
} from "../../store/services/adsService";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";
import { Image } from 'antd';
import { useGetDashboardQuery } from "../../store/services/userService";
import UsersPosts from "../Users/UserPosts";


const Main = () => {
  // const words = useSelector((state)=>state.au)

  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();


  // const { data: dashboard, isLoading } = useGetDashboardQuery();
  const [dashboard, setDashboard] = useState({});


  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    users: "",
    posts: "",
    ad: "",
    income: "",
  });





  const getDashboard = async () => {
    const onSuccess = (res) => {
      console.log('res', res.data)
      setLoading(false);
      setDashboard(res.data);


    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await getRequest("", routes.dashboard, true, onSuccess, onError);
  };



  useEffect(() => {
    getDashboard();
  }, [])





  return (
    <Layout>
      {loading ? (
        <div className="wrap wrapWidth flex flex-col items-center ">
          <Loader />
        </div>
      ) : (
        <div className="lading-page h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="home-grid w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 mb-8">
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#47A000]/20 h-16 w-16 rounded-2xl">
                  <UsersIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Users"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {dashboard?.users_count}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#0066A0]/20 h-16 w-16 rounded-2xl">
                  <NotesIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Post"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {dashboard.posts_count}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#A0009A]/20 h-16 w-16 rounded-2xl">
                  {/* <div className="text-[#A0009A]"> */}
                  <ChartIcon />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Orders"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {dashboard.ordersCount}
                  </h2>
                </div>
              </div>
            </div>

            <h2 className="text-[#18120F] text-xl min-w-fit font-medium" style={{marginBottom:10}} >{words["Latest posts"]}</h2>
            <UsersPosts posts={dashboard.latest_posts} />
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NewsDetailsModel
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default Main;
