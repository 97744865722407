import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
import Registration from "../Pages/Registration";

import Home from "../Pages/Home";
import Users from "../Pages/Users";
import Finance from "../Pages/Finance";
import FinanceDetail from "../Pages/FinanceDetail";
import Banner from "../Pages/Banner";
import Notification from "../Pages/Notification";
import Setting from "../Pages/Setting";
import ServiceDetail from "../Pages/ServiceDetail";
import ProductDetail from "../Pages/ProductDetail";
import StoreCreate from "../Pages/StoreCreate";
import EditUser from "../Pages/Users/EditUser";

import { useSelector } from "react-redux";

import MyProfile from "../Pages/MyProfile/MyProfile";
import InvoiceDetails from "../Pages/Finance/InvoiceDetails";
import Seminars from "../Pages/Seminars";
import Stores from "../Pages/Stores";
import UserDetails from "../Pages/Users/UserDetails";
import OrderDetails from "../Pages/Stores/OrderDetails";

const Routing = () => {
  // localStorage.setItem('admin', null);
  const { adminToken } = useSelector((state) => state.authReducer);
  // const isAdmin = localStorage.getItem("admin")? JSON.parse(localStorage.getItem("admin")):null;
  const adminDataString = localStorage.getItem("admin");
  let isAdmin = null;
  if (adminDataString) {
    try {
      // Attempt to parse the stored string as JSON
      isAdmin = JSON.parse(adminDataString);
    } catch (error) {
      // Handle parsing error (if the stored value is not valid JSON)
      console.error('Error parsing admin data:', error);
    }
  }


  useEffect(() => {

    if (!adminToken && window.location.pathname !== "/") {
      window.location.pathname = "/";
    }
    if (!isAdmin && window.location.pathname !== "/") {
      window.location.pathname = "/";
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />

      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>

        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
          <Route
            path="users"
            element={
              <Private>
                <Users />
              </Private>
            }
          />
          <Route
            path="user-details/:id"
            element={
              <Private>
                <UserDetails />
              </Private>
            }
          />
          <Route
            path="stores"
            element={
              <Private>
                <Stores />
              </Private>
            }
          />

          <Route
            path="order-details/:id"
            element={
              <Private>
                <OrderDetails />
              </Private>
            }
          />


          <Route
            path="seminar"
            element={
              <Private>
                <Seminars />
              </Private>
            }
          />

          <Route
            path="edit-user/:id"
            element={
              <Private>
                <EditUser />
              </Private>
            }
          />


          <Route
            path="finance"
            element={
              <Private>
                <Finance />
              </Private>
            }
          />
          <Route
            path="finance-detail"
            element={
              <Private>
                <FinanceDetail />
              </Private>
            }
          />
          <Route
            path="banner"
            element={
              <Private>
                <Banner />
              </Private>
            }
          />

          <Route
            path="notification"
            element={
              <Private>
                <Notification />
              </Private>
            }
          />

          <Route
            path="setting"
            element={
              <Private>
                <Setting />
              </Private>
            }
          />



          <Route
            path="profile"
            element={
              <Private>
                <MyProfile />
              </Private>
            }
          />
          <Route
            path="invoice"
            element={
              <Private>
                <InvoiceDetails />
              </Private>
            }
          />
        </Route>


      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
