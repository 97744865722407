import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import { DotMenuIcon, TrashIcon, EyeIcon2 } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
// import ApprovalRequests from "./ApprovalRequests";
import NewsTable from "./NewsTable";
import AdsTable from "./AdsTable";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import AppLoader from "../../components/Loader/AppLoader";
import { useSelector } from "react-redux";
import CategoriesTable from "./CategoriesTable";
import { collection, firestore, getDocs, addDoc, updateDoc, doc } from "../../firebase";
import ProductsTable from "./ProductsTable";
import { useGetCategoriesQuery, useGetOrdersQuery, useGetProductsQuery } from "../../store/services/storesService";
import OrdersTable from "./OrdersTable";


const Stores = () => {

  const { data: products, isLoading } = useGetProductsQuery();
  const { data: categories } = useGetCategoriesQuery();
  const { data: orders } = useGetOrdersQuery();
  const words = useSelector((state) => state.authReducer.words);

  const tabs = [
    {
      label: words["Product"],
      value: "products",
    },
    {
      label: words["Orders"],
      value: "orders",
    },
    {
      label: words["Category"],
      value: "categories",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [loading, setLoading] = useState(false);




  // const getCategories = async () => {

  //   try {
  //     const collectionRef = collection(firestore, 'productCategories');
  //     const snapshot = await getDocs(collectionRef);

  //     const docs = snapshot.docs.map(doc => {
  //       return {
  //         ...doc.data(),
  //         id: doc.id
  //       }

  //     });

  //     setCategories(docs);


  //   }
  //   catch (err) {

  //     toast.error(err);
  //   }

  // };



  // const getProductsWithImages = async () => {
  //   setLoading(true);
  //   try {
  //     // Step 1: Fetch all products from Firestore
  //     const productCollectionRef = collection(firestore, 'products');
  //     const productSnapshot = await getDocs(productCollectionRef);

  //     // Convert product docs to an array of product data
  //     const products = productSnapshot.docs.map(doc => ({
  //       id: doc.id,  // Product ID
  //       ...doc.data() // Product data
  //     }));

  //     // Step 2: Fetch all product images from Firestore
  //     const imageCollectionRef = collection(firestore, 'product_images');
  //     const imageSnapshot = await getDocs(imageCollectionRef);

  //     // Convert image docs to an array of image data
  //     const images = imageSnapshot.docs.map(doc => ({
  //       id: doc.id,
  //       ...doc.data()
  //     }));

  //     // Step 3: Combine products with their images
  //     const productsWithImages = products.map(product => {
  //       // Filter images that belong to the current product
  //       const productImages = images.filter(image => image.productId === product.id);
  //       return {
  //         ...product,
  //         images: productImages.map(img => img)  // Get array of image URLs
  //       };
  //     });
  //     // console.log('productsWithImages', productsWithImages);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     return [];
  //   }
  // };






  return (
    <>
      {loading && <AppLoader />}

      <Layout pageTitle={words["Stores"]}>
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex w-full border-b-[1px] border-b-themeColor border-transparent rounded ">
                {tabs.map((item, index) => (
                  <button
                    key={index}
                    className={`btn font-normal rounded ${activeTab === item.value
                      ? " bg-themeColor text-white"
                      : "bg-transparent text-black"
                      }`}
                    onClick={(e) => setActiveTab(item.value)}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
              {activeTab === "products" && (
                <ProductsTable
                  categories={categories}
                  data={products?.data ?? []}
                  loadingState={(val) => {
                    setLoading(val)
                  }}
                // refreshData={() => getProductsWithImages()}
                />
              )}

              {activeTab === "categories" && (
                <CategoriesTable
                  loadingState={(val) => {
                    setLoading(val)
                  }}
                  data={categories?.data} />
              )}
              {activeTab === "orders" && (
                <OrdersTable
                  loadingState={(val) => {
                    setLoading(val)
                  }}
                  data={orders?.data} />
              )}


            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Stores;
