import React, { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';


import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";

import Modal from "../../components/Modal";

import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDateString, getTimeString } from "../../utils/functions";
import { Dropdown, Menu, Button } from "antd";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import AddSeminarModal from "../../components/AddSeminarModel";
import { collection, firestore, getDocs, addDoc, deleteDoc, doc } from "../../firebase";
import AppLoader from "../../components/Loader/AppLoader";
import { useDeleteSeminarMutation, useGetSeminarsQuery } from "../../store/services/userService";

const Seminars = () => {
  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);

  const { data, isLoading } = useGetSeminarsQuery();
  const [deleteSeminar, response] = useDeleteSeminarMutation();



  const isAr = lang == 'ar';
  const classTextAlign = lang === 'ar' ? 'right' : 'left'
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [seminars, setSeminars] = useState([]);
  const [deletedId, setDeletedId] = useState("");


  const columnTextStyle = {
    // padding: '8px 16px',
    textAlign: classTextAlign,
    fontSize: '12px',
    color: '#292D32',
    whiteSpace: 'nowrap',
  }

  const columns = [

    {
      title: words["Title"],
      dataIndex: isAr ? "title_ar" : "title_en",
      key: "name",
    },
    {
      title: words["Description"],
      dataIndex: isAr ? "desc_ar" : "desc_en",
      key: "number",
      render: (_, record) => (
        <Tooltip
          title={isAr ? record.desc_ar : record.desc_en}
          arrow
        >
          <div
            style={{
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
              maxWidth: 100,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              ...columnTextStyle
            }}
          >
            {isAr ? record.desc_ar : record.desc_en}
          </div>
        </Tooltip>
      ),
    },
    {
      title: words["Duration"],
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: words["URL Link"],
      dataIndex: "url",
      key: "url",
      render: (_, record) => (

        <div
          className="text-sm text-black font-medium"
        >
          <a
            href={record.url}
            style={{
              textDecoration: "underline",
              ...columnTextStyle
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.url}
          </a>
        </div>

      ),
    },
    {
      title: words["Date start"],
      dataIndex: "start_date_time",
      key: "start_date_time",
      render: (_, record) => (

        <div
          style={{ ...columnTextStyle }}
        >
          {getDateString(record.start_date_time)}: {getTimeString(record.start_date_time)}
        </div>

      ),
    },
    {
      title: words["Type"],
      dataIndex: "type",
      key: "type",

    },



    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                setDeletedId(record.id);
                setOpenDeleteModal(true);
              }}
            >
              <>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
        </Dropdown>
      ),
    },
  ];


  useEffect(() => {
    console.log('here is the data', data);
    if (data?.status == 1) {
      setSeminars([...data?.live_seminars, ...data?.upcoming_seminars])
    }

  }, [data])


  const doDeleteUser = async (id) => {
    try {
      await deleteSeminar(deletedId).unwrap();
      // Handle success (e.g., show a message, update state)
    } catch (error) {
      // Handle error
    }
  };


  useEffect(() => {
    console.log('the response', response)
  }, [response])




  return (
    <>
      {
        isLoading || response.isLoading &&
        <AppLoader />
      }

      <Layout pageTitle={words["Seminars"]}>

        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>
              </div>

              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={seminars ?? []}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal
            // width="max-w-4xl"
            open={openModal} setOpen={setOpenModal}>
            <AddSeminarModal
              onSuccessCreate={() => {
                setOpenModal(false);
              }}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
              onSubmit={() => {
                doDeleteUser();
              }}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );
};


export default Seminars;
