import React, { useState } from "react";
import Select from "react-select";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetOrderDetailsQuery } from "../../store/services/storesService";

const OrderDetails = () => {

  const words = useSelector((state) => state.authReducer.words);
  const bookingData = [{}, {}, {}];
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const { data: order, isLoading: bookingLoading } = useGetOrderDetailsQuery(id);


  console.log('order', order);

  const options = [
    { value: "pending", label: "Pending" },
    { value: "process", label: "Process" },
    { value: "deliver", label: "Deliver" },
  ];

  return (
    <Layout pageTitle={words['Booking Detail']}>
      <div className="booking-page min-h-screen flex">
        {bookingLoading ? (
          <div className="wrap wrapWidth flex flex-col items-center ">
            <Loader />
          </div>
        ) : (
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex items-center justify-between gap-3">
              <div className="flex flex-col flex-1 gap-2">
                <div className="flex items-center gap-1">
                  <div className="bg-themeColor h-6 w-6 rounded-md" />
                  <p className="text-[#A7A3A0] font-normal text-sm">
                    {words['Date of order']} : {getDateString(order.data?.created_at)}
                  </p>
                </div>
                <div className="flex items-center gap-1">
                  <div className="bg-themeColor h-6 w-6 rounded-md" />
                  <p className="text-[#A7A3A0] font-normal text-sm">
                    {words['Order status']} : {capitalizeFirstLetter(order.data?.status)}
                  </p>
                </div>
              </div>

            </div>
            <div className="home-grid w-full grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-8">
              <div className="shadow-md-custom bg-white rounded-2xl overflow-hidden">
                <div className="responsive-table1 overflow-x-auto mb-20">
                  <div className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider" style={{ fontSize: 15 }} >{words['Order items']}</div>
                  <table className="min-w-full ">
                    <thead className="bg-[#F5F7FB]">
                      {/* divide-y divide-gray-200 */}
                      <tr>
                        <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                          {words['Name']}
                        </th>
                        <th className="px-2 py-2 text-left w-56 text-sm text-black font-medium">
                          {words['Price']}
                        </th>
                        <th className="px-2 py-2 text-left text-sm text-black font-medium">
                          {words['Quantity']}
                        </th>
                        {/* <th className="px-2 py-2 text-left text-sm text-black font-medium">
                          Sub {words['Total']}
                        </th> */}
                      </tr>
                    </thead>


                    <tbody className="bg-white divide-y divide-gray-200">

                      {order?.data?.order_items?.map((item, index) => (
                        <tr
                          key={index}
                          className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"
                            }`}
                        >
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {JSON.parse(item.name)}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.sale_price} {words['OMR']}
                          </td>
                          <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                            {item.quantity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-between gap-3 bg-[#F5F7FB] px-3 py-2 w-full">
                  <p className="text-black text-sm font-normal">
                    <span className="font-semibold">{words['Total']}</span> {order?.data?.total_price} {words['OMR']}
                  </p>
                </div>
              </div>
              <div className="shadow-md-custom flex flex-col bg-white rounded-2xl p-3">
                <div className="py-2 text-left text-sm font-medium text-black tracking-wider" style={{ fontSize: 15 }} >
                  {words['User Details']}
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <p className="text-[#808080] text-sm font-normal">{words['Name']}: {order?.data?.user?.fullname} </p>
                  <p className="text-[#808080] text-sm font-normal">
                    {words['Phone Number']}: {order?.data?.user?.number}
                  </p>
                  <p className="text-[#808080] text-sm font-normal">{words['Email']}: {order?.data?.user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default OrderDetails;
