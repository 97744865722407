export const en = {

  // SIDEBAR
  "Home": "Home",
  "Users": "Users",
  "Stores": "Stores",
  // DASHBOARD
  "Dashboard": 'Dashboard',
  "User": "User",
  "Post": "Post",
  "Income": "Income",
  "Orders": "Orders",
  "Seminar": "Seminar",
  "Latest posts" : "Latest posts",


  // SEMINARS PAGE
  'Seminars': "Seminars",
  "Seminar Name": "Seminar Name ",
  "Duration": "Duration",
  "URL Link": "URL Link",
  "Date start": "Date start",
  "Type": "Type",
  "Title (English)": "Title (English)",
  "Duration": "Duration",
  "Title (Arabic)": "Title (Arabic)",
  "Date start": "Date start",
  "Description (English)": "Description (English)",
  "Type": "Type",
  "Description (Arabic)": "Description (Arabic)",
  "URL link": "URL link",
  "Start date time": "Start date time",
  "End date time": "End date time",



  //STORES PAGE
  "Product": "Product",
  "Orders": "Orders",
  "Category": "Category",
  "Add product category": "Add product category",
  "Edit product category": "Edit product category",
  "Add Product": "Add Product",
  "Price": "Price",
  "Sale price": "Sale price",
  "Select category": "Select category",
  "Edit Product": "Edit Product",
  "Images": "Images",

  // COMMAN
  "Add": "Add",
  "Id": "Id",
  "Description": "Description",
  "Action": "Action",
  "Save": "Save",
  "Delete": "Delete",
  "Are you sure you want to delete the record ?": "Are you sure you want to delete the record ?",
  "Cancel": "Cancel",
  "Yes": "Yes",
  "Name": "Name",
  "Edit": "Edit",
  "Created at": "Created at",
  "Email": "Email",
  "Phone Number": "Phone Number",
  "Image": "Image",
  "Settings": "Settings",
  "Setting": "Setting",
  "Notifications": "Notifications",
  "Status": "Status",
  "OMR" : "OMR",


  //SETTINGS PAGE
  "Terms & Privacy Settings": "Terms & Privacy Settings",
  "Company Settings": "Company Settings",
  "Company Title": "Company Title",
  "Support Name": "Support Name",
  "Support Email": "Support Email",
  "Mobile": "Mobile",
  "Select Time Zone": "Select Time Zone",
  "Max Serviceable Distance (in Kms)": " Max Serviceable Distance (in Kms)",
  "Country Code": "Country Code",
  "Primary Color": "Primary Color",
  "Secondary Color": "Secondary Color",
  "Primary Shadow Color": "Primary Shadow Color",
  "OTP System": "OTP System",
  "Login Image": "Login Image",
  "Company Whatsapp": "Company Whatsapp",
  "Company X": "Company X",
  "Company Instagram": "Company Instagram",
  "Company Address": "Company Address",
  "Privacy policy": "Privacy policy",
  "Terms & Condition": "Terms & Condition",

  // USERS PAGE
  "View": "View",
  "User Info": "User Info",
  "Career planning Tools": "Career planning Tools",
  "Stages": "Stages",
  "Posts": "Posts",
  "Title": "Title",
  "Likes" : "Likes",

  // NOTIFICATIONS PAGE
  "User name": "User name",
  "Send Notification": "Send Notification",

  // ORDERS PAGE

  "Order price": "Order price",
  "Items count": "Items count",
  "Quantity" : "Quantity",
  "Total" : "Total",
  "Date of order" : "Date of order",
  "Order status" : "Order status",
  "User Details" : "User Details",
  "Order items" : "Order items",








  //   "Latest posts": "Latest posts",
  //   No: "No",
  //   Image: "Image",
  //   Date: "Date",
  //   
  //   Publisher: "Publisher",
  //   
  //   "Latest orders": "Latest orders",
  //   User: "User",
  //   Post: "Post",
  //   AD: "AD",
  //   
  //   News: "News",
  //   "Ali ahamd": "Ali ahamd",


  //   ID: "ID",
  //   Action: "Action",
  //   Reject: "Reject",
  //   Published: "Published",
  //   "In-Review": "In-Review",
  //   ServiceDetails: "ServiceDetails",
  //   "Basic Details": "Basic Details",
  //   Images: "Images",
  //   "Already Registered": "Already Registered",
  //   "User Register": "User Register",
  //   "Service Details": "Service Details",

  //   Accessory: "Accessory",





  //   Logo: "Logo",
  //   "Favicon Logo": "Favicon Logo",
  //   "Half Logo": "Half Logo",
  //   "Admin Logos": "Admin Logos",
  //   "Company Address": "Company Address",
  //   "Company Short Description": "Company Short Description",
  //   "Copyright Details": "Copyright Details",
  //   "Support Hours": "Support Hours",
  //   "Customer Terms and Conditions": "Customer Terms and Conditions",
  // 
  //   "Customer privacy policy": "Customer privacy policy",

  //   
  //   


  //   Photos: "Photos",
  //   off: "off",
  //   on: "on",



  //   City: "City",
  //   


  //   "ID Card": "ID Card",
  //   
  //  



  //   Location: "Location",
  //   Link: "Link",
  //   "Store Name": "Store Name",

  // "Logo.png": "Logo.png",



  //   Stores: "Stores",
  //   Interval: "Interval",
  //   
  //   "Stat Date": "Stat Date ",
  //   "End Date": "End Date",
  //   
  //   "User type": "User type",
  //   Home: "Home",
  //   Posts: "Posts",
  //   Ads: "Ads",
  //   Users: "Users",
  //   Finance: "Finance",
  //   Banner: "Banner",
  //   Notification: "Notification",
  //   Staff: "Staff",
  //   
  //   "Approval requests": "Approval requests",
  //   "Add Post": "Add Post",
  //   url: "url",
  //   "Start date": "Start date",
  //   
  //   "End date": "End date",
  //   "Total amount": "Total amount",

  //   "Payment status": "Payment status",
  //   Description: "Description",
  //   Type: "Type",
  //   "Publishing Add": "Publishing Add",
  //   id: "id",
  //   Duration: "Duration",
  //   "Ad Id": "Ad Id",
  //   "User name": "User name",
  //   "Transaction date": "Transaction date",
  //   "User phone": "User phone",
  //   "Send Notification": "Send Notification",
  //   "Dashboard": 'Dashboard',
  //   "Add Staff": "Add Staff",
  //   


  //   "Careers": "وظائف",
  //   "His Majesty Photo": "صور المقام السامي",
  //   "Decrees": "مراسيم",
  //   "Omani projects": "مشاريع عمانية",
  //   "Tourism in Oman": "السياحة في عمان",
  //   "Disaster awareness": "التوعية عند الكوارث",
  //   "Omani history": "التاريخ العماني",
  //   "Bave men of Oman": "رجال عمان الاشاوس",
  //   "Oman Vision 2040": "رؤية عمان ٢٠٤٠",
  //   "Sports in Oman": "الرياضة في عمان",
  //   "Omani personalities": "شخصيات عمانية",
  //   "Palestinian cause": "القضية اللسطينية",



  //   "وظائف ": "Careers",
  //   "الأخبار": "News",
  //   "صور المقام السامي": "His Majesty Photos",
  //   "مراسيم ": "Decrees",
  //   "مشاريع عمانية": "Omani projects",
  //   "السياحة في عمان ": "Tourism in Oman",
  //   "التوعية عند الكوارث ": "Disaster awareness",
  //   "التاريخ العماني": "Omani history",
  //   "رجال عمان الاشاوس ": "Brave men of Oman",
  //   "رؤية عمان ٢٠٤٠": "Oman Vision 2040",
  //   "الرياضة في عمان ": "Sports in Oman",
  //   "شخصيات عمانية": "Omani personalities",
  //   "القضية الفلسطينية": "Palestinian cause",




  //   'Food': 'مطاعم',
  //   'Hotels': 'فنادق',
  //   'Discounts': 'تخفيضات',
  //   'Property': 'عقارات',
  //   'مطاعم': 'Food',
  //   'فنادق': 'Hotels',
  //   'تخفيضات': 'Discounts',
  //   'عقارات': 'Properties',




  //   "Tax": "Tax",
  //   'Ad information': 'Ad information',
  //   'Ad video or image': 'Ad video or image',
  //   'Image or Video': 'Image or Video',
  //   'Posted by': 'Posted by',
  //   "1 Month": "1 Month",
  //   "3 Months": "3 Months",
  //   "6 Months": "6 Months",
  //   "1 Year": "1 Year",
  //   "Pending": "Pending",
  //   "Rejected": "Rejected",
  //   "Approved": "Approved",
  //   "Paid": "Paid",
  //   "Unpaid": "Unpaid",
  //   "Ad details": "Ad details",
  //   "Approve": "Approve",
  //   "Rejected Reason": "Rejected Reason",
  //   "Enter rejected reason": "Enter rejected reason",
  //   "Rejected reason": "Rejected reason",
  //   "Add Image & Video": "Add Image & Video",

  //   "Enter post title": "Enter post title",
  //   "Enter Post description": "Enter Post description",
  //   "rejected": "rejected",
  //   "published": "published",
  //   "in-review": "in-review",
  //   "Post details": "Post details",
  //   "Publishing Posts": "Publishing Posts",
  //   "Phone": "Phone",
  //   'Id': 'Id',
  //   
  //   
  //   
  //   "Active": "Active",
  //   "Inactive": "Inactive",
  //   "Transaction id": "Transaction id",
  //   "Start/End date": "Start/End date",

  //   "Enter description": "Enter description",
  //   "In-review": "In-review",
  //   "View Ad": "View Ad",
  //   "Enter post url": "Enter post url",
  //   "Edit Banner": "Edit Banner",
  //   "Add Banner": "Add Banner",
  //   "
  //   "
  //   "
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   
  //   "Add user": "Add user",
  //   "Password": "Password",
  //   "Enter Phone": "Enter Phone",
  //   "Enter password": "Enter password",
  //   "Enter Name": "Enter Name",
  //   
  //   "Update": "Update",
  //   "Profile": "Profile",

  //   'Ad settings': 'Ad settings',
  //   "Price/Month": "Price/Month",
  //   "Price/3 Months": "Price/3 Months",
  //   "Price/6 Months": "Price/6 Months",
  //   "Price/Year": "Price/Year",

};
