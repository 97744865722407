import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const userService = createApi({
  reducerPath: "user",
  tagTypes: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = localStorage.getItem('admin-token')
      // reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {

    return {
      getUsers: builder.query({
        query: () => {
          return {
            url: "/api/admin/get_users",
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),


      addSeminar: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/seminars",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["seminar"],
      }),

      getSeminars: builder.query({
        query: () => {
          return {
            url: "/api/admin/seminars",
            method: "GET",
          };
        },
        providesTags: ["seminar"],
      }),

      deleteSeminar: builder.mutation({
        query: (id) => {
          return {
            url: `/api/admin/seminars/${id}`, // Ensure the slash is included
            method: "DELETE",
          };
        },
        invalidatesTags: ["seminar"], // Use invalidatesTags to indicate that the seminar list needs to be refreshed
      }),



      getUsersDetails: builder.query({
        query: (id) => {
          return {
            url: "/api/admin/get_user/" + id,
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),


      getDashboard: builder.query({
        query: () => {
          return {
            url: "admin/dashboard",
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),

      



    };
  },
});
export const { useGetUsersQuery,useGetDashboardQuery, useGetUsersDetailsQuery, useGetSeminarsQuery, useAddSeminarMutation, useDeleteSeminarMutation } = userService;
export default userService;
