import React, { useState } from 'react';
import { EditIcon, ImageIcon, TrashIcon } from '../assets/Icons';
import { Icons } from '../assets/Icons/Svgs';
import { IMAGE_URL } from '../Api/constants';

// import DeleteIcon from './DeleteIcon'; // Replace with your actual delete icon component


const ImageUploader = ({ images = [], onUploadImages, onDeleteImage }) => {
    //   const [images, setImages] = useState([]);

    // Handle image selection
    const handleImageChange = (e) => {
        onUploadImages(e);
        // const selectedImages = Array.from(e.target.files);
        // setImages((prevImages) => [...prevImages, ...selectedImages]);

    };

    const handleDeleteImage = (index, item) => {
        onDeleteImage(index, item)
        // setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="img-box-wrapper flex flex-wrap">

            <div
                className="img-box flex flex-col items-center justify-center h-28 w-28 rounded-full cursor-pointer bg-[#D9D9D9] relative"
                onClick={() => document.getElementById("upload_img").click()}
            >
                <div className="flex items-center justify-center h-full w-full">
                    <ImageIcon />
                </div>
            </div>


            {images.length > 0 ? (
                images.map((img, index) => (
                    <div
                        key={index}
                        className="img-box flex flex-col items-center justify-center h-28 w-28 rounded-full cursor-pointer bg-[#D9D9D9] relative m-2"
                    >
                        <img
                            alt={`img-${index}`}
                            src={typeof img?.image_path == 'string' ? IMAGE_URL + img.image_path : URL.createObjectURL(img)}
                            className="h-full w-full rounded-full object-contain"
                        />
                        <div
                            className="flex items-center justify-center absolute bg-white h-8 w-8 p-[6px] rounded-full bottom-0 right-0 z-40 cursor-pointer"
                            onClick={() => handleDeleteImage(index, img)} // Delete image on click
                        >
                            <TrashIcon color="red" />
                        </div>
                    </div>
                ))
            ) : (null
                // <div
                //     className="img-box flex flex-col items-center justify-center h-28 w-28 rounded-full cursor-pointer bg-[#D9D9D9] relative"
                //     onClick={() => document.getElementById("upload_img").click()}
                // >
                //     <div className="flex items-center justify-center h-full w-full">
                //         <ImageIcon />
                //     </div>
                // </div>
            )}

            <input
                type="file"
                accept="image/*"
                multiple
                title="image"
                id="upload_img"
                className="hidden cleanbtn"
                onChange={handleImageChange}
            />

            <div
                className="flex items-center justify-center absolute bg-white border border-themeColor h-8 w-8 p-[6px] rounded-full bottom-0 right-0 z-40"
                onClick={() => document.getElementById("upload_img").click()}
            >
                {images.length === 0 && <EditIcon />}
            </div>
        </div>
    );
};

export default ImageUploader;
